<template>
  <section>
    <div class="">
      <!-- Tableau pour afficher les taxes -->
      <div class="tableau">
        <b-card no-body class="py-2">
          <b-row class="mx-2">
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mt-1"
            >
              <label>Entrées</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-50 mr-1"
              />

              <b-button variant="primary" @click="redirection">
                <feather-icon icon="PlusIcon" class="mx-auto" />
                Réaliser un emprunt
              </b-button>
            </b-col>

            <!-- Search -->
            <b-col cols="12" md="6" class="mt-1">
              <div class="d-flex align-items-center justify-content-end">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="filtreEmprunt"
                    class="d-inline-block"
                    placeholder="Rechercher par : libelle, date, montant..."
                  />
                </b-input-group>
              </div>
            </b-col>
          </b-row>

          <q-loader-table
            :success="state.success"
            :empty="state.empty"
            :warring="state.warring"
          />

          <!-- Le tableau affichant les taxes -->
          <b-table
            hover
            responsive
            primary-key="id"
            :per-page="perPage"
            :current-page="currentPage"
            :items="emprunts"
            :fields="tableColumns"
            :filter="filtreEmprunt"
            show-empty
            empty-text="Aucun emprunt enregistré"
            class="bg-white"
            v-if="state.success === true"
          >
            <!-- Bouton de status -->
            <template #cell(stat)="data">
              <div
                v-if="
                  getSolde(data.item.id).length !== 0 &&
                    getSolde(data.item.id).length ==
                      getAPayer(data.item.id).length
                "
              >
                <b-badge variant="success">
                  Soldé
                </b-badge>
              </div>
              <div
                v-if="
                  (getSolde(data.item.id).length === 0 &&
                    getSolde(data.item.id).length <
                      getAPayer(data.item.id).length) ||
                    (getSolde(data.item.id).length !== 0 &&
                      getSolde(data.item.id).length <
                        getAPayer(data.item.id).length)
                "
              >
                <b-badge variant="danger">
                  A payer
                </b-badge>
              </div>
            </template>

            <template #cell(impaye)="data">
              <span class="text-danger text-nowrap font-weight-bold">
                {{ formatMoney(data.item.impaye) }}
              </span>
            </template>

            <template #cell(paye)="data">
              <span class="text-success text-nowrap">
                {{ formatMoney(data.item.paye) }}
              </span>
            </template>

            <!-- Status -->
            <template #cell(status)="data">
              <b-badge v-if="data.item.status === 'remboursé'" variant="success">
                <!-- {{ data.item.status }} -->
                <span>Remboursé</span>
              </b-badge>
                 <b-badge
                v-if="data.item.status === 'partiel'"
                variant="warning"
              >
                <!-- {{ data.item.status }} -->
                <span> <span>Partiel</span></span>
              </b-badge>
              <b-badge
                v-if="data.item.status === 'à rembourser'"
                variant="danger"
              >
                <!-- {{ data.item.status }} -->
                <span> <span>A rembourser</span></span>
              </b-badge>
            </template>
            <!-- Montant -->
            <template #cell(montant)="data">
              <span>{{ formatMoney(data.item.montant) }}</span>
            </template>

            <!-- Bouton de taux -->
            <template #cell(taux)="data">
              <p>{{ data.item.taux===null ? 0  : data.item.taux }} %</p>
            </template>
            <!-- Bouton de delai -->
            <template #cell(del)="data">
              <span>
                {{
                  calculDelai(
                    data.item.date_emprunt,
                    data.item.id
                  )
                }}
              </span>
            </template>
            <!-- Bouton d'action -->
            <!-- <template #cell(actions)="data">
            <div class="w-50 mx-auto d-flex justify-content-around">
              <b-button variant="gradient-info" class="btn-icon" v-b-modal.modal-update @click="update(data.item.id)">
                <feather-icon icon="CheckCircleIcon" />
              </b-button>
              <b-button variant="gradient-danger" class="btn-icon" @click="deleteEmprunt(data.item.id)">
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </div>
          </template> -->

            <template #cell(actions)="data">
              <div class="text-nowrap py-1">
                <feather-icon
                  icon="TrashIcon"
                  :id="`invoice-row-${data.item.id}-trash-icon`"
                  size="16"
                  class="cursor-pointer"
                  @click="confirmText(data.item.id)"
                />
                <b-tooltip
                  title="supprimer une depense"
                  :target="`invoice-row-${data.item.id}-trash-icon`"
                />

                <feather-icon
                  :id="`invoice-row-${data.item.id}-preview-icon`"
                  icon="EyeIcon"
                  size="16"
                  class="mx-1 cursor-pointer"
                  @click="previewEmprunt(data.item)"
                />
                <b-tooltip
                  title="Detail de la depense"
                  :target="`invoice-row-${data.item.id}-preview-icon`"
                />

                <!-- Dropdown -->
                <b-dropdown
                  variant="link"
                  toggle-class="p-0"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>

                  <b-dropdown-item
                    v-b-modal.modal-update
                    @click="update(data.item.id)"
                  >
                    <feather-icon icon="PlusIcon" />
                    <span class="align-middle ml-50"
                      >Modalités de paiements</span
                    >
                  </b-dropdown-item>

                  <!-- <b-dropdown-item 
                   @click="updateStatus(data.item.id)"
                 >
												<feather-icon icon="TrendingUpIcon" />
												<span class="align-middle ml-50"> Emprunt soldé</span>
											</b-dropdown-item> -->
                </b-dropdown>
              </div>
            </template>
          </b-table>

          <!-- Les boutons de pagination -->
          <div class="mx-2 mt-2 pb-1">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"></span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="empruntTotal"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
    </div>

    <!-- modal login-->
    <b-modal
      id="modal-update"
      cancel-variant="outline-secondary"
      ok-title="Valider"
      cancel-title="Annuler"
      centered
      title=" Modalités de remboursements"
      @ok="rembourser"
    >
      <b-card no-body class="invoice-padding form-item-section">
        <div ref="form" class="repeater-form" v-if="remboursementTable">
          <!-- <h5 class="text-success">Dejà remboursé: {{ formatMoney(remboursementTable[0].emprunt.paye) }}</h5>
          <h5 class="text-danger">Reste à payer: {{ formatMoney(remboursementTable[0].emprunt.impaye) }}</h5> -->
          <!-- <h5>Reste à rembourser: {{ remboursementTable[0].emprunt }}</h5> -->
          <b-row
            v-for="(item, index) in remboursementTable"
            :key="index"
            ref="row"
            class="pb-2"
          >
            <!-- Item Form -->
            <!-- ? This will be in loop => So consider below markup for single item -->
            <b-col cols="12">
              <!-- <h5 class="text-success text-center">Dejà noté: {{ formatMoney(item.emprunt.paye) }}</h5>
          <h5 class="text-danger text-center">Reste à noté: {{ formatMoney(item.emprunt.impaye) }}</h5> -->
              <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
              <div class="d-none d-lg-flex">
                <b-row class="flex-grow-1 px-1">
                  <!-- Single Item Form Headers -->
                  <b-col cols="4" lg="4">
                    Date
                  </b-col>
                  <b-col cols="3" lg="3">
                    Montant
                  </b-col>
                  <b-col cols="5" lg="5">
                    Compte
                  </b-col>
                </b-row>
                <div class="form-item-action-col" />
              </div>

              <!-- Form Input Fields OR content inside bordered area  -->
              <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
              <div
                class="d-flex border rounded"
                :class="{ border: item.status === 'remboursé' }"
              >
                <b-row class="flex-grow-1 p-2">
                  <!-- Single Item Form Headers -->
                  <b-col cols="12" lg="12">
                    <b-row class="d-flex justify-items-center">
                      <b-col cols="4" lg="4">
                        <label class="d-inline d-lg-none">Date</label>
                        <b-form-input
                          v-model="item.date_remboursement"
                          type="text"
                          class="mb-2"
                          readonly
                        />
                      </b-col>
                      <b-col cols="4" lg="4">
                        <label class="d-inline d-lg-none">Montant</label>
                        <b-form-input
                          v-model="item.montant_remboursement"
                          type="text"
                          class="mb-2"
                          readonly
                        />
                      </b-col>

                        <b-col cols="4" lg="4">
                        <label class="d-inline d-lg-none">Compte</label>
                        <b-form-input
                          v-model="item.libelle_compte"
                          type="text"
                          class="mb-2"
                          readonly
                        />
                      </b-col>
                     
                      <b-col class="w-100 " cols="12" lg="12"  >
                        <!-- <label class="d-inline d-lg-none hidden">Date</label> -->
                        <b-button class="w-100" variant="primary" @click="updateStatus(item.id,item.emprunt_id,index)" :disabled="item.status === 'regler' ? true : false">
                          <feather-icon  :icon="item.status !== 'regler' ? 'PlusIcon': 'CheckIcon'" class="mx-auto" />
                           {{item.status === 'regler' ? 'réglé' : 'régler'}}
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <template #modal-footer >
      
      <b-button  class="hidden"
     
      >
        
      </b-button>
    </template>
    </b-modal>
    {{ __NEXT }}
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BModal,
  BFormInput,
  BFormGroup,
  BButton,
  VBModal,
  BForm,
  BLink,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BFormTextarea,
  VBToggle,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import URL from "@/views/pages/request";
import axios from "axios";
import flatPickr from "vue-flatpickr-component";
import QLoaderTable from "@/components/__partials/loaders/qLoaderTable.vue";
import qDeviseUtils from "@/utils/qDeviseUtils";
import { qDecrypted } from "@/services/qeCrypt";

export default {
  components: {
    vSelect,
    flatPickr,
    VBToggle,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BModal,
    BFormGroup,
    VBModal,
    BForm,
    BImg,
    BLink,
    BForm,
    BFormCheckbox,
    BInputGroup,
    BFormTextarea,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    QLoaderTable,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      __emprunt_id: "",
      isError: true,
      isError__date: true,
      selectedCompte: "",
      item: {
        date: "",
        montant: "",
      },
      remboursements: [{ date: "", montant: "", status: "" }],

      modelRemboursement: { date: "", montant: "", status: "" },

      montantValidation: false,
      emprunts: [],
      remboursementTable: [],
      statusList: [
        { status: "Remboursé" },
        { status: "Partiel" },
        { status: "A rembourser" },
      ],
      state: {
        success: false,
        empty: false,
        warring: false,
      },
      comptes: [],
      qCompte: [],
      inputError: {
        path: '',
        message: '',
        id: 0
      },
      filtreEmprunt: "",
      perPage: 30,
      currentPage: 1,
      empruntTotal: 0,
      tableColumns: [
        // { key: 'stat', label: 'Statut' },
        { key: "status", sortable: true, label: "Statut" },
        { key: "libelle", sortable: true },
        { key: "montant", label: "montant + taux", sortable: true },
        { key: "paye", sortable: true, label: "Remboursé" },
        // { key: "impaye", sortable: true, label: "Reste à rembourser" },
        // { key: "taux", sortable: true },
        { key: "del", label: "Prochain remboursement", sortable: true },
        { key: "date_emprunt", sortable: true },
        // { key: "date_remboursement", sortable: true },
        { key: "actions" },
      ],
      perPageOptions: [30, 50, 100, 150, 250, 500],
      GIVE: false,
    };
  },
  computed: {
    __NEXT: function() {
      if (this.GIVE === true) {
        this.emprunts.length === 0
          ? ((this.state.empty = true), (this.state.success = false))
          : ((this.state.success = true), (this.state.empty = false));
      }
      return;
    },
  },
  async mounted() {
    document.title = "Emprunts - Ediqia";


    try {
      await axios
        .get(URL.EMPRUNT_LIST)
        .then((response) => {
          this.emprunts = response.data.emprunts;
          this.remboursementList = response.data.remboursements;
          this.emprunts.reverse();
          this.GIVE = true;
           this.getComptes()
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      this.state.empty = false;
      this.state.warring = true;
      this.state.success = false;
      console.log(error);
    }
  },

  methods: {
    //DUPLICATEUR

    onChangeCompte(e) {
      this.qCompte.push(
        {
          index:this.qCompte.length,
          id: e.id
        }
      );
      console.log(this.qCompte);
    },

    async getComptes(){
           /*****
     * @Taxes : list des taxes
     */
    await axios
        .get(URL.COMPTE_LIST)
        .then((response) => {
          this.comptes = response.data[0]
          console.log('compte',this.comptes);
          this.comptes.reverse();
        })

        .catch((error) => {
          console.log(error);
        });

    },

    addNewItemInItemForm() {
      if (this.montantValidation) {
        return;
      } else {
        this.$refs.form.style.overflow = "hidden";
        this.remboursements.push(
          JSON.parse(JSON.stringify(this.modelRemboursement))
        );

        this.$nextTick(() => {
          this.trAddHeight(this.$refs.row[0].offsetHeight);
          setTimeout(() => {
            this.$refs.form.style.overflow = null;
          }, 350);
        });
      }
    },
    removeItem(index) {
      if (this.remboursements.length === 0) {
        //  this.showForm === false;
      }

      this.remboursements.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    updateItemForm(index, val) {
      const { date } = val;
      this.remboursements[index].date = date;
    },
    updateItemMontant(index, val) {
      const { montant } = val;
      this.remboursements[index].montant = montant;
    },
    updateItemStatus(index, val) {
      const { status } = val;
      this.remboursements[index].status = status;
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },

    //page preview

    previewEmprunt(data) {
      const currectEmprunt = this.emprunts.filter(
        (item) => item.id === data.id
      );

      localStorage.setItem("emprunt", JSON.stringify(currectEmprunt[0]));
      this.$router.push("/preview-emprunt");
    },

    formatMoney(num) {
      const dataToDecrypt = localStorage.getItem("entreprise_info");
      return qDeviseUtils.formatter(qDecrypted(dataToDecrypt), num);
    },

    convert(num, taux) {
      const formatter = new Intl.NumberFormat("ci-CI", {
        style: "currency",
        currency: "XOF",
        minimumFractionDigits: 2,
      });

      let sum = parseFloat(num * (1 + taux / 100));

      return formatter.format(sum.toFixed(2));
    },

    //validation
    validateDateRemboursement() {

      for (let i = 0; i < this.remboursements.length; i++) {
        const compareData = this.remboursements[i].date;
        this.isError__date = false;
        if (this.remboursementTable[0].emprunt.date_emprunt > compareData) {
          this.isError__date = true;
          this.remboursements[i].montant = "";
          this.remboursements[i].date = "";
          return this.$swal({
            icon: "error",
            title: "Oops...",
            text:
              "les dates de remboursement doivent être superieurs ou égale à la date de l'emprunt",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      }
    },

    // valideMontantTotal() {
    //   let sum = parseFloat(
    //     this.remboursementTable[0].emprunt.montant *
    //       (1 + this.remboursementTable[0].emprunt.taux / 100)
    //   );
    //   const montantTotal = sum.toFixed(2);
    //   let element = 0;

    //   for (let i = 0; i < this.remboursements.length; i++) {
    //     element += Number(this.remboursements[i].montant);
    //   }

    //   if (montantTotal < element) {
    //     // this.montantValidation = true;
    //     this.$swal({
    //       icon: "error",
    //       title: "Oops...",
    //       text:
    //         "La somme des montants de remboursement doit être égale ou superieur à la da de l'emprunt !",
    //       customClass: {
    //         confirmButton: "btn btn-primary",
    //       },
    //       buttonsStyling: false,
    //       // }).then((result) => {
    //       // if (result.value) {
    //       //   // this.remboursements.splice(this.remboursements.length - 1, 1);
    //       //   // this.trTrimHeight(this.$refs.row[0].offsetHeight);
    //       // }
    //     });
    //   }

    //   //  else {
    //   //   this.montantValidation = false;
    //   // }
    // },

    valideMontantTotal() {
      let sum = parseFloat(this.remboursementTable[0].emprunt.impaye);
      const montantTotal = sum.toFixed(2);

      for (let i = 0; i < this.remboursements.length; i++) {
        let element = Number(this.remboursements[i].montant);
        this.isError = false;
        if (montantTotal < element) {
          this.isError = true;
          // this.montantValidation = true;
          this.remboursements[i].montant = "";
          this.remboursements[i].date = "";
          return this.$swal({
            icon: "error",
            title: "Oops...",
            text:
              "La somme des montants de remboursement doit être égale ou superieur à la da de l'emprunt !",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
            // }).then((result) => {
            // if (result.value) {
            //   // this.remboursements.splice(this.remboursements.length - 1, 1);
            //   // this.trTrimHeight(this.$refs.row[0].offsetHeight);
            // }
          });
        }
      }

      //  else {
      //   this.montantValidation = false;
      // }
    },

    async rembourser() {
      try {
        const config = {
          headers: {
            Accept: "application/json",
          },
        };

        const data = {
          emprunt_id: this.__emprunt_id,
          count: this.remboursements.length,
          item: this.remboursements,
        };
        if (this.isError === false && this.isError__date === false) {
          await axios
            .post(URL.EMPRUNT_REMBOURSEMENT, data, config)
            .then((response) => {
              if (response.data) {
                this.successUpd();
                axios
                  .get(URL.EMPRUNT_LIST)
                  .then((response) => {
                    this.emprunts = response.data.emprunts;
                    this.remboursementList = response.data.remboursements;
                    for (let i = 0; i <= this.remboursements.length; i++) {
                      const remb = this.remboursements[i];
                      this.removeItem(i);
                      remb[i].date = "";
                      remb[i].montant = "";
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } catch (error) {
        console.log("trycatch error", error);
      }
    },

    async updateStatus(id,emprunt_id,index) {

      try {
        const config = {
          headers: {
            Accept: "application/json",
          },
        };

        // const _compte_id = this.qCompte.find((compte)=>{
        //       return compte.index===index
        //   })

        const data = { 
          id: id,
          // compte_id:_compte_id.id,
        status: 'regler'
        };
      
        
          await axios
          .post(URL.EMPRUNT_UPDATESTATUT, data, config)
          .then((response) => {
            if (response.data) {
              this.successState();
              
              axios
                .get(URL.EMPRUNT_LIST)
                .then((response) => {
                  this.inputError.path = '';
          this.inputError.message = '';
                  this.emprunts = response.data.emprunts;
                    this.remboursementList = response.data.remboursements;
                   this.remboursementTable = this.remboursementList.filter((item) => {
                  return item.emprunt_id === emprunt_id;
                  
               });
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      

        
      } catch (error) {
        console.log("trycatch error", error);
      }
    },

    successDel() {
      this.$swal({
        title: "Réussi",
        text: "Emprunt supprimé avec succès !",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    successUpd() {
      this.$swal({
        title: "Réussi",
        text: " Remboursement ajouté avec succès !",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    successState() {
      this.$swal({
        title: "Bravoooo",
        text: " Vous avez remboursé votre emprunt !",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    redirection() {
      this.$router.push("/newEmprunt");
    },

    async deleteEmprunt(indentifiant) {
      const id_delete = {
        id: indentifiant,
      };
      try {
        await axios
          .post(URL.EMPRUNT_DESTROY, id_delete)
          .then((response) => {
            response.data;
            axios
              .get(URL.EMPRUNT_LIST)
              .then((response) => {
                this.emprunts = response.data.emprunts;
                this.remboursementList = response.data.remboursements;
                this.empruntTotal = this.emprunts.length;
              })
              .catch((error) => {
                console.log(error.response.data.errors);
              });
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
            }
          });
        this.emprunts.splice(index, 1);
      } catch (error) {
        console.log(error.type);
      }
    },

    confirmText(id) {
      this.$swal({
        title: "Êtes vous sûr?",
        text: "Cette depense sera supprimé définitivement !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteEmprunt(id);
        }
      });
    },

    update(id) {
      this.__emprunt_id = id;
      this.remboursementTable = this.remboursementList.filter((item) => {
        return item.emprunt_id === id;
      });
    },

    updateClient(index) {
      this.fakeData[index].taxeName = this.newTaxeName;
      this.fakeData[index].taxeValue = this.newTaxeValue;
    },

    calculDelai(debut, emprunt_id ) {

      let diff = {}; // Initialisation du retour

      const __REMB__  = this.remboursementList.filter((remb) => { return remb.emprunt_id === emprunt_id})

      for (let i = 0; i < __REMB__.length; i++) {
        const remb = __REMB__[i];
       
         const date1 = new Date();
      const date2 = new Date(remb.date_remboursement);

   if(date1 < date2 && remb.status !== 'regler'){
     let tmp = date2 - date1;

      tmp = Math.floor(tmp / 1000); // Nombre de secondes entre les 2 dates
      diff.sec = tmp % 60; // Extraction du nombre de secondes

      tmp = Math.floor((tmp - diff.sec) / 60); // Nombre de minutes (partie entière)
      diff.min = tmp % 60; // Extraction du nombre de minutes

      tmp = Math.floor((tmp - diff.min) / 60); // Nombre d'heures (entières)
      diff.hour = tmp % 24; // Extraction du nombre d'heures

      tmp = Math.floor((tmp - diff.hour) / 24); // Nombre de jours restants
      diff.day = tmp;

      break;
   }else{
     diff.day = 0
   }

      
      

     
      }
   
      if (diff.day) {
        return `${diff.day} jours`;
      } else if (diff.day === 0) {
        return "Moins de 0 jour";
      }
    },
  },
};
</script>

<style lang="scss">
// @import '@core/scss/vue/libs/vue-select.scss';
//   .table-base {
//     margin: 30px auto 0;
//   }

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.add-new-client-header {
  padding: $options-padding-y $options-padding-x;
  color: $success;
  &:hover {
    background-color: rgba($success, 0.12);
  }
}

.tableau {
  box-shadow: 0px 6px 46px -21px rgba(0, 0, 0, 0.75);
}

.table-card {
  width: 100%;
  margin: auto;
  border-radius: 13px;
}

.table-card thead tr {
  border-radius: 13px;
  background-color: rgb(68, 68, 68) !important;
}

.table-card thead tr th {
  background-color: rgb(68, 68, 68) !important;
  color: white;
}

.add-btn {
  position: absolute;
  right: 0;
  top: -50px;
  background-color: #450077;
}

.none {
  display: none;
}
.block {
  display: inline-block;
}

.border {
  border-color: cornflowerblue;
}
</style>
